import {FC, memo, useEffect, useRef, useState} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';

import cls from './Dropdown.module.scss';

type Option = {
    value: string;
    label: string;
    image?: string;
};

interface DropdownProps {
    className?: string
    onChange?: (value: string) => void;
    options: Option[];
    label?: string;
    selected?: Option;
}

export const Dropdown = memo((props: DropdownProps) => {
    const {
        onChange,
        className,
        options,
        label,
    } = props;

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option | null>(props.selected || null);
    const [hovered, setHovered] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleSelect = (option: Option) => {
        setSelected(option);
        onChange?.(option.value);
        setOpen(false);
    };

    useEffect(() => {
        if (open && !hovered) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [open, hovered]);

    useEffect(() => {
        const handleMouseEnter = () => {
            setHovered(true);
        };
        const handleMouseLeave = () => {
            setHovered(false);
        };
        if (dropdownRef.current) {
            dropdownRef.current.addEventListener("mouseenter", handleMouseEnter);
            dropdownRef.current.addEventListener("mouseleave", handleMouseLeave);
        }
        return () => {
            if (dropdownRef.current) {
                dropdownRef.current.removeEventListener("mouseenter", handleMouseEnter);
                dropdownRef.current.removeEventListener("mouseleave", handleMouseLeave);
            }
        };
    }, []);

    return (
        <div className={classNames(cls.Dropdown, {}, [className])} ref={dropdownRef}>
            {label && <label className={cls.title}>{label}</label>}
            <div className={classNames(cls.selected, { [cls.selected_open]: open })} onClick={handleToggle}>
                {selected?.image && (
                    <img
                        className={cls.image}
                        src={selected.image}
                        alt={selected.label}
                    />
                )}
                <div className={cls.label}>
                    {selected ? selected.label : "Выберите опцию"}
                </div>
            </div>
            {open && (
                <div className={cls.options}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={cls.option}
                            onClick={() => handleSelect(option)}
                        >
                            <div className={cls.content}>
                                {option?.image && (
                                    <img
                                        className={cls.image}
                                        src={option.image}
                                        alt={option.label}
                                    />
                                )}
                                <div className={cls.label}>{option.label}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});
