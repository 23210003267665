import {ButtonHTMLAttributes, memo, MouseEvent, useState} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';

import './Button.scss';

export enum ButtonVariant {
    TEXT = 'text',
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "text" | "contained" | "outlined";
    disabled?: boolean;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

export const Button = memo((props: ButtonProps) => {
    const {
        children,
        variant = "contained",
        disabled = false,
        onClick,
        className,
    } = props;

    const [ripple, setRipple] = useState({ x: 0, y: 0, show: false });

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setRipple({ x, y, show: true });
        setTimeout(() => setRipple((prev) => ({ ...prev, show: false })), 500);
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <button
            className={classNames(`button ${variant}`, {}, [className])}
            onClick={handleClick}
            disabled={disabled}
        >
            {children}
            {ripple.show && (
                <span
                    className="ripple"
                    style={{
                        left: `${ripple.x}px`,
                        top: `${ripple.y}px`,
                    }}
                />
            )}
        </button>
    );
});
