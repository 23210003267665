import { ChangeEvent, InputHTMLAttributes, memo } from "react";
import { classNames } from "../../lib/classNames/classNames";

import cls from "./Input.module.scss";

type HTMLInputProps =
    Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'autoComplete'>

interface InputProps extends HTMLInputProps {
    label?: string;
    value: string;
    type?: string;
    placeholder?: string;
    onChange: (value: string) => void
}

export const Input = memo((props: InputProps) => {
    const {
        label,
        value,
        type = "text",
        placeholder,
        onChange
    } = props;

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    return (
        <div className={classNames(cls.Input)}>
            {label && <label className={cls.label}>{label}</label>}
            <input
                className={cls.field}
                type={type}
                value={value}
                onChange={onChangeHandler}
                placeholder={placeholder}
            />
        </div>
    );
});
