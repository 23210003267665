import {FC} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';

import cls from './Footer.module.scss';

interface FooterProps {
    className?: string
}

export const Footer: FC<FooterProps> = (props) => {
    const {className} = props;
    return (
        <div className={classNames(cls.Footer, {}, [className])} />
    );
}
