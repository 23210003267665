import {FC} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';
import {Button, ButtonVariant} from "shared/ui/Button/Button";

import logo from 'shared/assets/images/logo.svg';

import cls from './Navbar.module.scss';

interface NavbarProps {
    className?: string
    handleSidebarOpen?: () => void;
}

export const Navbar: FC<NavbarProps> = (props) => {
    const {
        className,
        handleSidebarOpen,
    } = props;

    return (
        <div className={classNames(cls.Navbar, {}, [className])}>
            <div className={cls.Navbar__wrapper}>
                <div className={cls.Navbar__logo}>
                    <img src={logo} alt="logo"/>
                </div>
                <div className={cls.Navbar__menu}>
                    <div className={cls.Navbar__menu_item}>
                        <a href="#">Exchange</a>
                    </div>
                    <div className={cls.Navbar__menu_item}>
                        <a href="#">Locations</a>
                    </div>
                    <div className={cls.Navbar__menu_item}>
                        <a href="#">Help</a>
                    </div>
                    <div className={cls.Navbar__menu_item}>
                        <a href="#">About</a>
                    </div>
                </div>
            </div>
            <div className={classNames(cls.Navbar__wrapper, {}, [cls.Navbar__buttons])}>
                <Button variant={ButtonVariant.TEXT}>Login</Button>
                <Button className={cls.Navbar__button}>Sing up</Button>
            </div>
            <div className={cls.Navbar__burger} onClick={handleSidebarOpen}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}
