import {createRoot} from 'react-dom/client';
import App from "app/App";

import 'app/styles/index.scss';

const rootNode = document.getElementById('root') as HTMLElement;

const root = createRoot(rootNode);
root.render(
    <App />
);
