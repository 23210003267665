import {useEffect, useState} from "react";
import { Navbar } from "widgets/Navbar";
import { MainPage } from "pages/MainPage";
import {Footer} from "../widgets/Footer";
import {Sidebar} from "../widgets/Sidebar";

const App = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSidebarOpen = () => {
        setIsOpen(true);
    }

    const handleSidebarClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        document.body.className = 'app_light_theme'
    }, []);

    return (
        <>
            <div className='app'>
                <Navbar handleSidebarOpen={handleSidebarOpen}/>
                <Sidebar isOpen={isOpen} onClose={handleSidebarClose} />
                <MainPage />
            </div>
            <Footer />
        </>
    )
}

export default App;
