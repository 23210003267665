import {FC, useState} from 'react';

import {ExchangeBlock} from "widgets/Exchange";

import {classNames} from 'shared/lib/classNames/classNames';
import {Button, ButtonVariant} from "shared/ui/Button/Button";

import {BeginnerBlock} from "./BeginnerBlock/BeginnerBlock";
import {PortfolioBlock} from "./PortfolioBlock/PortfolioBlock";

import cls from './MainPage.module.scss';
import {Sidebar} from "../../../widgets/Sidebar";

interface MainPageProps {
    className?: string
}

export const MainPage: FC<MainPageProps> = (props) => {
    const {className} = props;

    return (
        <div className={classNames(cls.MainPage, {}, [className])}>
            <div className={cls.MainPage__firstblock}>
                <div className={cls.MainPage__wrapper}>
                    <div className={cls.MainPage__title}>
                        <h1>Buy&nbsp;&&nbsp;Sell Crypto&nbsp;Easy</h1>
                        <h2>with <span>bit</span>expert</h2>
                        <h2 className={cls.title}>Coming soon!</h2>
                    </div>
                    <ExchangeBlock />
                    {/*<div className={cls.MainPage__subtitle}>*/}
                    {/*    <p>The simplest and safest way to buy or sell bitcoin and <br /> other cryptocurrencies without the help of a specialist.</p>*/}
                    {/*</div>*/}
                    {/*<div className={cls.MainPage__buttons}>*/}
                    {/*    <Button*/}
                    {/*        className={cls.MainPage__button}*/}
                    {/*    >*/}
                    {/*        Get Started*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        className={cls.MainPage__button}*/}
                    {/*        variant={ButtonVariant.OUTLINED}*/}
                    {/*    >*/}
                    {/*        Beginner’s Guide*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
                <div className={cls.MainPage__blocks}>
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockOutlined])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />

                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockOutlined])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockOutlined])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />

                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockOutlined])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />

                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />

                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockOutlined])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block)} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                    <div className={classNames(cls.MainPage__blocks_block, {}, [cls.MainPage__blocks_blockFulled])} />
                </div>
            </div>

            <BeginnerBlock />

            <PortfolioBlock />
        </div>
    );
}
