import {memo, useEffect, useState} from "react";

import {classNames} from "shared/lib/classNames/classNames";

import cls from "./Sidebar.module.scss";
import {Button, ButtonVariant} from "../../../shared/ui/Button/Button";

interface SidebarProps {
    isOpen: boolean;
    onClose: () => void;
}

export const Sidebar = memo((props: SidebarProps) => {
    const {isOpen, onClose} = props;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        setIsSidebarOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setIsSidebarOpen(false);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    return (
        <>
            <div className={classNames(cls.overlay, { [cls.overlayOpen]: isOpen })} onClick={handleClose}></div>
            <div className={classNames(cls.wrapper, { [cls.wrapperOpen]: isOpen })}>
                <div className={cls.content}>
                    <div className={cls.header}>
                        <div className={cls.close} onClick={handleClose}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className={cls.body}>
                        <a href="#">Exchange</a>
                        <a href="#">Locations</a>
                        <a href="#">Help</a>
                        <a href="#">About</a>

                        <div className={cls.bodyButtons}>
                            <Button variant={ButtonVariant.TEXT}>Sign In</Button>
                            <Button>Sign Up</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
