import {FC} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';
import {Skeleton} from "shared/ui/Skeleton/Skeleton";

import cls from './PortfolioBlock.module.scss';

interface PortfolioBlockProps {
    className?: string
}

export const PortfolioBlock: FC<PortfolioBlockProps> = (props) => {
    const {className} = props;
    return (
        <div className={classNames(cls.PortfolioBlock, {}, [className])}>
            <div className={cls.title}>
                <h2>Start today with your portfolio</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <div className={cls.blocks}>
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                </div>
                <div className={cls.blocks}>
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                    <Skeleton width='100%' height={90} borderRadius='16px' />
                </div>
            </div>
        </div>
    );
}
