import {FC} from 'react';

import {classNames} from 'shared/lib/classNames/classNames';
import {Skeleton} from "shared/ui/Skeleton/Skeleton";

import cls from './BeginnerBlock.module.scss';

interface BeginnerBlockProps {
    className?: string
}

export const BeginnerBlock: FC<BeginnerBlockProps> = (props) => {
    const {className} = props;
    return (
        <div className={classNames(cls.BeginnerBlock, {}, [className])}>
            <div className={cls.title}>
                <h2>Beginner's guide</h2>
            </div>
            <div className={cls.blocks}>
                <Skeleton width='100%' height={500} borderRadius='16px' />
                <Skeleton width='100%' height={500} borderRadius='16px' />
                <Skeleton width='100%' height={500} borderRadius='16px' />
            </div>
        </div>
    );
}
