import { FC, useCallback, useState } from 'react';

import { classNames } from 'shared/lib/classNames/classNames';
import { Input } from "shared/ui/Input/Input";
import { Dropdown } from "shared/ui/Dropdown/Dropdown";
import { Button } from "shared/ui/Button/Button";

import card from "shared/assets/images/icons/card.svg";
import usd from 'shared/assets/images/icons/fiat/usd.svg';
import eur from 'shared/assets/images/icons/fiat/eur.svg';
import btc from 'shared/assets/images/icons/crypto/btc.svg';
// import { ReactComponent as SwapArrows } from 'shared/assets/images/icons/swap_arrows.svg';

import cls from './Exchange.module.scss';

interface ExchangeProps {
    className?: string
}

export const Exchange: FC<ExchangeProps> = (props) => {
    const {className} = props;

    const [sendValue, setSendValue] = useState<string>('100.00');
    const [getValue, setGetValue] = useState<string>('0.0010000');

    const onChangeSendValue = useCallback((value: string) => {
        setSendValue(value);
    }, []);

    const onChangeGetValue = useCallback((value: string) => {
        setGetValue(value);
    }, []);

    return (
        <div className={classNames(cls.Exchange, {}, [className])}>
            {/*<div className={cls.title}>*/}
            {/*    <h2>Buy & Sell Crypto</h2>*/}
            {/*</div>*/}
            <div className={cls.wrapper}>
                <div className={cls.wrapperBlock}>
                    <div className={cls.inputWrapper}>
                        <Input
                            value={sendValue}
                            onChange={onChangeSendValue}
                            label='Send'
                        />
                        <Dropdown
                            className={cls.dropdown}
                            options={[{value: '1', label: 'EUR', image: eur }, {value: '2', label: 'USD', image: usd } ]}
                            selected={{value: '1', label: 'EUR', image: eur }}
                        />
                    </div>

                    {/*<Button variant={ButtonVariant.TEXT} className={cls.swapBtn}>*/}
                    {/*    <SwapArrows />*/}
                    {/*</Button>*/}

                    <div className={cls.inputWrapper}>
                        <Input
                            value={getValue}
                            onChange={onChangeGetValue}
                            label='Get'
                        />
                        <Dropdown
                            className={cls.dropdown}
                            options={[{value: '1', label: 'BTC', image: btc }]}
                            selected={{value: '1', label: 'BTC', image: btc }}
                        />
                    </div>
                </div>
                <div className={cls.payments}>
                    <Dropdown
                        options={[{value: '1', label: 'Credit Card', image: card}, {value: '2', label: 'Crypto'}]}
                        label='Payment Method'
                        selected={{value: '1', label: 'Credit Card', image: card}}
                        className={cls.paymentsDropdown}
                    />
                    <Button className={cls.buyCrypto}>
                        Buy Crypto
                    </Button>
                </div>
            </div>
        </div>
    );
}
